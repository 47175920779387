import React from 'react'
import { Link } from 'gatsby'
import '../styles/_header.css'

const Header = () => (
  <header className="header">
    <div className="container">
      <nav className="nav">
        <ul className="header__list-links">
          <li>
            <Link to="/about/">About</Link>
          </li>

          <li>
            <Link to="/resume">Resume</Link>
          </li>

          <li>
            <Link to="/blog">Blog</Link>
          </li>
        </ul>

        <ul className="header__list-social">
          <li>
            <a href="https://www.facebook.com/ajkimlol" target="_blank" rel="noopener noreferrer">
              <span className="fab fa-facebook-f" rel="noopener noreferrer" target="_blank" />
            </a>
          </li>

          <li>
            <a href="https://twitter.com/kimMoolah/" target="_blank" rel="noopener noreferrer">
              <span className="fab fa-twitter" rel="noopener noreferrer" target="_blank" />
            </a>
          </li>

          <li>
            <a href="https://www.instagram.com/and.kim/" target="_blank" rel="noopener noreferrer">
              <span className="fab fa-instagram" rel="noopener noreferrer" target="_blank" />
            </a>
          </li>

          <li>
            <a href="https://www.linkedin.com/in/andrewjkimcs/" target="_blank" rel="noopener noreferrer">
              <span className="fab fa-linkedin" rel="noopener noreferrer" target="_blank" />
            </a>
          </li>

          <li>
            <a href="mailto:ajkim@berkeley.edu" target="_blank" rel="noopener noreferrer">
              <link className="fas fa-at" rel="noopener noreferrer" target="_blank" />
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </header>
)

export default Header